import { Routes, Route } from "react-router-dom";

import Editor from "@monaco-editor/react";

import AuthCheck from "./lazy";
import { QRCodeEditor } from "./(private)/print/page";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<AuthCheck />} />
      <Route path="/editor" element={<Editor theme="vs-light" language="json" />} />
      <Route path="/qr-code-editor" element={<QRCodeEditor />} />
    </Routes>
  );
};

export default AllRoutes;
