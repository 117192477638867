import { createTheme, ThemeProvider } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useTheme } from "@mui/material/styles";
import { designTokens } from "@/designTokens";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
  }
}

const createShadows = Array.from({ length: 25 }).map((_, index) => {
  if (index === 0) {
    return "none";
  }

  const distance = index / 2;
  const spread = distance * 3;

  const opacity = (1 - index / 24) ** 4 * 0.25 + 0.01;

  return `${distance}px ${distance}px ${spread}px 0px rgba(41, 19, 129, ${opacity})`;
}) as [
  "none",
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

export const theme = createTheme({
  palette: {
    primary: {
      light: designTokens.colors.lavender.main,
      main: designTokens.colors.purple.main,
      contrastText: "#fff",
    },
    secondary: {
      main: designTokens.colors.green.main,
      contrastText: "#fff",
    },
    tertiary: {
      main: designTokens.colors.lightBlue.main,
    },
    background: {
      paper: "rgb(255, 255, 255)",
      default: "rgb(247, 247, 255)",
    },
    divider: designTokens.colors.purple.main,
    text: {
      primary: "rgb(0, 0, 0)",
      secondary: "rgb(100, 100, 100)",
    },
    info: {
      main: designTokens.colors.lavender.main,
    },
    error: {
      main: "#C91400",
    },
    success: {
      main: designTokens.colors.green.main,
    },
  },
  shadows: createShadows,
  shape: {
    borderRadius: 24,
  },
  typography: {
    h1: designTokens.typography.h1,
    h2: designTokens.typography.h2,
    h3: designTokens.typography.h3,
    h4: designTokens.typography.h4,
    h5: designTokens.typography.h5,
    h6: designTokens.typography.h6,
    subtitle1: designTokens.typography.copyLargeHero,
    body1: designTokens.typography.copyLarge,
    subtitle2: designTokens.typography.copySmallHero,
    body2: designTokens.typography.copySmall,
    button: designTokens.button.common,
  },
});

export { styled, createStyles, makeStyles, useTheme, Theme, ThemeProvider, createTheme };
