import dayjs from "dayjs";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import utc from "dayjs/plugin/utc";

import { defaultNS, fallbackLng, ns, resources, supportedLngs } from "./locales/resources";

/**
 * Date
 */
require("dayjs/locale/de");

dayjs.extend(utc);
dayjs.locale("de");

i18n.use(LanguageDetector).init({
  detection: {
    order: ["localStorage", "navigator"],
    lookupLocalStorage: "language",
    caches: ["localStorage"],
  },
  supportedLngs,
});
i18n.use(initReactI18next).init({
  ns,
  defaultNS,
  resources,
  fallbackLng,
  interpolation: {
    escapeValue: false,
  },
  supportedLngs,
});

export default i18n;
