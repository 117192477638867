export const venue = {
  team: {
    members: {
      title: "Members",
      text: "Here you can invite your employees. Depending on their roles, they will have different functions and permissions.",
    },
    invites: {
      title: "Invitations",
      text: "Here you can see your open invitations. Once they are accepted (by logging in into the app or administration), they will be displayed in your team. You can withdraw invitations at any time. If someone from your team cannot sign up, make sure the email address is spelled correctly.",
      textOwner:
        "Has access to the administration and can create QR codes and change settings here. In the app, QR codes can be assigned, push notifications received, and incidents managed.",
      textAwareness:
        "In the app, QR codes can be assigned, push notifications received, and incidents managed.",
      textAwarenessTeam: "In the app, QR codes can be assigned and current cases can be viewed.",
      revoke: "Revoke",
      button: "Invite",
      modal: {
        title: "Invite",
        name: "Name",
        nameError: "Please enter a name",
        email: "Email",
        emailError: "Please enter a valid email address",
        role: "Role",
        invite: "Send",
      },
    },
    securityHint:
      "You currently have no one in your team as 'Awareness Lead'. Only they will receive push notifications about incoming cases in the app.",
    editPermission: {
      roleSuccess: "Role changed successfully",
    },
  },
  print: {
    template: "Template",
    format: "Format",

    general: {
      saferspacesTemplate: "saferspaces Template",
      customTemplate: "Custom Template",
      uploadCustomTemplate: "Upload Custom Template",
    },
    breadcrumbNavigation: {
      all: "All",
      position: "Location-based QR Codes",
      venue: "Location-independent QR Code",
      sticker: "Stickers",
      poster: "Posters",
      individual: "QR Codes",
      "venue-code": "Venue QR Code",
      "85x55mm": "Business cards",
    },
    position: {
      title: "Location-based QR Codes",
      description:
        "These QR codes must be assigned specific positions before or during installation. Since each QR code is linked to a position, no location data of the scanning person need to be recorded.",
      lastCreatedPrintTemplate: "Last created",
      noPrintTemplateCreated: "No print templates available",
      createInProgress: "{{count}} {{format}} templates are being created.",
      createError: "An error occurred while creating the print templates.",
      createNew: "create new",
      createNewCount: "Count",
      downloadProgress: "Complete",
      posters: {
        title: "Posters",
        description:
          "The posters are the main contact point for your visitors to get in touch. They are particularly suitable for fixed positions to draw attention to the concept.",
        availableFormats: "A4 / A3 / A2",
        formats: {
          A2: "A2 Posters",
          A3: "A3 Posters",
          A4: "A4 Posters",
        },
      },
      stickers: {
        // description: "Eignen sich insbesondere für Rückzugsräume wie Toiletten oder Umkleiden.",
        description:
          "Are particularly suitable for retreat areas such as toilets or changing rooms.",
        title: "Stickers",
        formats: {
          A6: "Sticker",
        },
      },
    },
    venue: {
      description:
        "Via the QR code, people can contact the awareness team without passing on their location. This is suitable, for example, for mobile teams on the move, or stewards who do not have a fixed location.",
      title: "Location-independent QR Code",
      "85x55mm": {
        description:
          "Are particularly suitable for mobile teams on the move, or stewards who do not have a fixed location.",
        title: "Business Cards",
        availableFormats: "85 x 55 mm",
      },
      single: {
        description: "Stand-alone QR code for printing on flexible templates.",
        title: "Venue QR Code",
      },
    },
    // back: "Back",

    //
    // downloadNumberOfPosters_one: "Download template for <1>{{count}}</1> poster.",
    // downloadNumberOfPosters_other: "Download templates for <1>{{count}}</1> posters.",
    // downloadNumberOfPosters_zero: "",
    // multipleDescription: "Stand-alone QR codes for printing on flexible templates.",
    // multipleTitle: "Individual QR Codes",

    // posterDescription:
    //   "Optionally for use with saferspaces template or own template with defined dimensions.",
    // posterTitle: "Posters",
    // selectLocations: "Select locations",
    // selectLocationsDescription: "For which locations should the posters be generated?",
    // selectQRCodes: "Select QR codes",

    // format: "Format",
    // posterSizes: "A2 / A3 / A4",
    // stickersSizes: "A6",
    // "85x55mmSize": "85 x 55 mm",
    // template: "Template",
    // title: "Print",
    // unassignedQRCodes: "There are unassigned qr codes",
    // unassignedQRCodesCount: "{{count}} posters",

    // posters: {
    //   description:
    //     "QR codes on these posters must be assigned specific positions before or during installation. Since each QR code is linked to a position, no location data of the scanning person needs to be collected.",
    //   title: "Posters",
    //   saferspacesTemplate: "saferspaces Template",
    //   customTemplate: "Custom Template",
    //   locations: "Locations",
    //   template: "Template",

    //   formats: {
    //     A2: "A2",
    //     A3: "A3",
    //     A4: "A4",
    //   },
    // },

    settings: {
      background: "Background",
      bleed: "Bleed",
      cutContour: "Cut contour",
      cropMarks: "Crop marks",
      foreground: "Foreground",
      margin: "Margin",
    },
  },
  feedback: {
    allowFeedback: "Allow Feedback",
    allowFeedbackDescription:
      "Would you like to give your visitors the opportunity to provide anonymous feedback in case of inactivity of your place?",
    link: "Feedback Link",
    text: "Text",
    button: "Text on the button",
    save: "Save",
    createFeedbackCode: "Create Feedback Code",
  },
  locations: {
    title: "QR Codes",
    locationDescription:
      "Here you can see all your QR codes. You can edit, delete or add new ones.",
    positionDescription:
      "The positions of the individual QR codes can then be defined more precisely if necessary. For example, you can create a QR code for the entrance area and another one for the area in front of the stage. You can do this either directly when creating the place or later via the assignment in the app.",
    bookingHint:
      "With the test access, you can only create one place. Later you can add more here.",
    createLocations: "Create locations",
    noCodes: "Currently, here are no QR codes assigned.",
    move: "Move",
    edit: "Edit",
    delete: "Delete",
    numberOfCodes: "Number of assigned QR Codes: {{count}}",
    modal: {
      title: "Create QR Codes",
      locationDescription:
        "Start creating locations now. A <1>location</1> can be, for example, the first floor of your club or a specific area of your festival. Which locations would you like to create?",
      locationNamePlaceholder: "Name",
      locationBookingHint:
        "With the test access, you can only create one location. Later you can add more here.",
      positionDescription:
        "Think briefly about where the QR codes should hang. How many QR codes do you want to create each time?",
      positionBookingHint: "With the test access, you can only create one code here each time.",
      guest: "QR Codes for guests",
      staff: "QR Codes for the staff",
      back: "Back",
      create: "Create",
      addLocation: "Add another location",
      next: "Next",
    },
    editLocationModal: {
      title: "Edit location",
      locationNameLabel: "Name",
      saveButton: "Save",
      deleteButton: "Delete",
      confirmDeleteDescription:
        "Confirm the deletion of all QR codes for the location '{{location}}'. This action cannot be undone.",
      confirmDeleteYes: "Yes, delete",
    },
    deletePositionModal: {
      text: "Please confirm the deletion of the selected QR codes. Make sure that they are no longer attached to the respective location. You can reassign the QR codes afterwards using the saferspaces app.",
    },
    editPositionModal: {
      title: "Edit QR Code",
      shortcode: "Shortcode",
      positionNameLabel: "Name",
      saveButton: "Save",
      deleteButton: "Delete",
      confirmDeleteDescription:
        "Confirm the deletion of the QR code '{{position}}'. This action cannot be undone.",
      confirmDeleteYes: "Yes, delete",
    },
  },
  settings: {
    title: "Settings",
    featureType: {
      free: "This function is available in the free version ",
      basic: "This function is available in the basic version",
      premium: "This function is available in the premium version",
    },
    texts: {
      title: "Texts",
      description:
        "By editing the appropriate text field, you can see the visitor view in the app on the right.",
    },
    qrCodes: {
      title: "QR code settings",
      description:
        "Here you can edit the individual settings and texts for your place of deployment. With the screen views, you can see exactly what information is displayed to the people seeking help. <1>Attention:</1> All settings are directly transferred to the view of the visitors.",
      questionTitle: "Show questions",
      questionText:
        "Specifies whether questions for situational classification should be displayed after scanning the QR code.",
      chatTitle: "Enable chat",
      chatText: "Specifies whether a chat should be enabled after scanning the QR code.",
      positionTitle: "Allow sharing current position",
      positionText: "Allows visitors to share their current position.",
      activeTitle: "Activate/deactivate",
      activeText:
        "When activated, allows the deactivation of QR codes when no one is currently on-site.",
      visitorTitle: "QR codes for visitors",
      staffTitle: "QR codes for staff",
      showQuestions: "Show questions",
      hasChat: "Allow chat",
      enablePositionSharing: "Enable position sharing",
      contactNumber: "Emergency number",
      contactNumberText: "If a phone number is provided, visitors can call it directly.",
      activeIsRequired: "Limited availability",
      activeIsRequiredText:
        "You should activate the limited availability if the posters are also accessible to visitors when no team is on duty.",
      waitingTime: "Response time",
      waitingTimeText:
        "Specifies how long it takes for an incident to be considered 'not accepted'. After this time, the person will be directed to the chat and an automated message will be sent.",
      isActiveTitle: "Switch active/inactive",
      isActiveText: "Your QR codes can be activated or deactivated either here or through the app.",
      deactivate: "Deactivate",
      deactivateText:
        "Your codes will be deactivated. This means your visitors can no longer request direct help.",
      activate: "Activate",
      activateText:
        "Your codes will be activated. Make sure someone is on site, or that no one but you has access to the QR codes.",
    },
    directChat: { title: "Venue QR code", label: "Active" },
    theme: {
      texts: "Texts",
      automaticFirstMessage: "Automatic reply",
      automaticFirstMessageHelperText:
        "Automatic message that is being sent, after a new chat opens",
      automaticUnavailableMessage: "Automatic Reply",
      automaticUnavailableMessageHelperText: "Message that is displayed if no one is available",
      waitingHeadline: "Headline",
      waitingHeadlineAccepted: "Headline",
      waitingHeadlineAcceptedHelperText: "Headline after the incident has been accepted",
      waitingHeadlineHelperText: "Headline right after scanning the code",
      waitingMessage: "Text",
      waitingMessageAccepted: "Text",
      waitingMessageAcceptedHelperText: "Hint after the incident has been accepted",
      waitingMessageHelperText: "Hint that is being shown, directly after scanning a QR code",
      images: "Images",
      imagesLogo: "Logo",
      imagesBackground: "Background",
    },
    security: "Security",
    securityGeoFencing: "Activate geofencing",
    notifications: "Notifications",
    notificationsNew: "New notifications",
  },
} as const;
