import React from "react";

import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

export const RefTextField = React.forwardRef((props: TextFieldProps, _ref) => {
  return <MuiTextField variant="standard" {...props} inputRef={_ref} />;
});

const TextField = React.forwardRef((props: TextFieldProps, _ref) => {
  return <MuiTextField variant="standard" {...props} />;
});

export default TextField;
